

.app {
  z-index: 0;
  margin: 0;
  padding: 0;
}

.header {
  display: flex;
  align-items: center;
  background-color: var(--theme);
  color: var(--light-font);
  padding: 0.75rem 1rem;
}

.h-logo {
  margin: 0.25rem auto 0.25rem 0;
}

.nav-button {
  cursor: pointer;
  font-size: 1.5em;
  padding: 0.5rem 1rem;
}
.nav-concrete {
  cursor: pointer;
  text-align: right;
  padding: 0.5rem 1rem;
}

.navigation {
  z-index: 100;
  background-color: #333;
  color: var(--light-font);
}
.nav-header {
  display: flex;
  align-items: center;
  margin-bottom: 3rem;
}
.nav-h2 {
  margin: 0 auto 0 0;
}
.nav-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nav-item {
  cursor: pointer;

  width: 100%;

  padding: 0.75rem;

  font-size: 1.5em;
  text-align: center;

  transition: background-color 0.2s ease-in-out;
}
.nav-item:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.view-report {
  z-index: 250;
  background: var(--background);
}

.loader {
  z-index: 300;
  background: var(--theme);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  opacity: 1;

  transition: transform 0.4s ease-in-out, opacity 0.5s ease !important;
}

.loader.fade {
  opacity: 0;
}

.loader-spinner {
  margin-bottom: 3rem;
}

.loader-message {
  font-size: 1em;
  font-style: italic;
}

.loader-footer {
  position: absolute;
  bottom: 1rem;
  color: rgba(0, 0, 0, 0.8);
  font-style: italic;
}

.login {
  z-index: 150;

  text-align: center;
  padding-top: 0 !important;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-logo {
  width: 80%;
  max-width: 400px;
  margin-bottom: 1rem;
}

.concrete-calc {
  z-index: 30;
  background-color: var(--background);
  text-align: center;
}

.con-calc-btn {
  margin-top: 2rem !important;
}
.con-calc-item {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}
.con-calc-item label {
  padding: 0.25rem;
}
.con-calc-item input {
  text-align: center;
}
.con-calc-ro {
  background-color: rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.create-project {
  background-color: #eee;
  text-align: center;
}
.create-field-container {
  margin: 0.5rem;
}
.create-field {
  text-align: center;
  width: 100%;
  margin: 0.25rem 0;
}
.create-field::placeholder {
  color: rgba(0,0,0,0.3);
  font-style: italic;
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;

  transform: translate(0,0);

  transition: transform 0.4s ease-in-out;
}
.container.left {
  transform: translateX(-100%);
}
.container.right {
  transform: translateX(100%);
}
.container.bottom {
  transform: translateY(100%);
}

.section {
  background-color: var(--background);
  padding: 1rem;
}

.create-project-button {
  text-align: center;
  font-size: 1.25em;
}

.create-project-i i {
  margin-right: 0.5rem;
}

.project-item {
  cursor: pointer;
  background-color: #dfdfdf;
  
  padding: 0.5rem 0 0.5rem 0.75rem;
  margin: 0.5rem 0;

  border-radius: 0.25rem;

  display: flex;
  align-items: center;

  transition: background-color 0.2s ease-in-out;
}
.project-item:hover {
  background-color: #cfcfcf;
}

.item-name {
  font-size: 1.5em;
}

.item-updated {
  font-size: 0.9em;
  color: #555;
  font-style: italic;
}

.item-menu {
  position: relative;
  padding: 0.75rem 1.5rem;
  margin-right: 0;
  margin-left: auto;
}

.item-menu-area {
  z-index: 80;
  position: absolute;
  right: 0px;
  top: 3rem;

  background-color: var(--theme);
  color: #fff;
  border-radius: 0.25rem;

  width: 200px;

  text-align: right;
  overflow: hidden;
  max-height: 0px;

  transition: max-height 0.3s ease;
}
.item-menu-area.show {
  max-height: 100px;
}

.item-menu-option-button {
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: center;

  transition: background-color 0.2s ease;
}

.item-menu-option-button:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.back-link {
  cursor: pointer;
  color: #333;
  
  transition: color 0.2s ease-in-out;
}
.back-link:hover {
  color: #222;
}

.project-contract {
  font-weight: bold;
  font-size: 2em;
  margin: 1rem 0;
}

.summary-details {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
}
.summary-details:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.project-label {
  font-weight: bold;
  width: 170px;
}

.project-value {
  cursor: pointer;
  max-width: calc(100% - 170px);
}
.project-value input {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  transition: background-color 0.2s ease-in-out;
}
.project-value input:focus {
  background-color: rgba(255, 255, 255, 0.9);
}

.create-report-button {
  margin-top: 1rem !important;
}

.project-contact {
  padding: 0.15rem 0.25rem;
}

.project-report {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  border-radius: 0.25rem;
  background-color: rgba(0, 0, 0, 0.05);

  transition: background-color 0.2s ease-in-out;
}
.project-report:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.report-details {
  margin-right: auto;
}

.report-sub {
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.6);
  font-style: italic;
}

.report-project {
  font-size: 1.2em;
}

.report-date-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}
.report-date {
  cursor: pointer;
  border: 1px solid rgba(0,0,0,0.1);
  padding: 0.5rem 1rem;
  background-color: #fdfdfd;
  font-size: 1.1em;
  text-align: center;
  width: 100%;
}
.DayPicker {
  background-color: #fdfdfd;
}

.dp-drop {
  max-height: 0;
  overflow: hidden;

  transition: max-height 0.2s ease-in-out;
}
.dp-drop.show {
  max-height: 300px;
}

.report-field {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.report-field.project-summary {
  margin-left: 0.5rem;
}
.report-field.project-summary label {
  min-width: 170px;
}
.report-field.project-summary div {
  text-align: center;
  width: 150px;
}

.report-field select {
  cursor: pointer;
}
.report-field label {
  display: block;
  min-width: 130px;
}

.report-type {
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  font-size: 1.1em;
}

.report-worker-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.report-worker-container input {
  flex-grow: 1;
  padding: 0.35rem 0.75rem;
  margin: 0.125rem;
}
.report-worker-container input::placeholder {
  font-size: 0.6em;
  font-style: italic;
}
.rw-number {
  width: 80px;
  text-align: center;
}


.task-container {
  padding: 0rem 0.5rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
  background-color: rgba(0, 0, 0, 0.05);
}
.task-header h3 {
  padding: 0 0 0 .25rem;
  margin-right: auto;
}

.task-header {
  display: flex;
  align-items: center;
}

.task-markers {
  display: flex;
  align-items: center;
}

.task-mark-button {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;

  transition: background-color 0.2s ease;
}
.task-mark-button:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.task-group-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.task-group {
  flex-grow: 1;
  position: relative;
  margin: 1rem 0.25rem;
}

.task-group label {
  position: absolute;
  left: 0;
  top: -1.25rem;
  font-size: 0.8em;
  width: 100%;
}

.task-group {
  width: 70px;
}

.task-group input {
  display: block;
  margin: 0;
  width: 100%;
  text-align: center;
}

.task-group input:read-only {
  cursor: default;
  background-color: rgba(0, 0, 0, 0.075);
}


.report-notes {
  width: 100%;
  min-height: 150px;
  font-size: 1.1em;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}


.add-task-button {
  text-transform: capitalize;
}

.hide {
  display: none;
}


.report {
  background-color: var(--background);
}

.report-timespan, .report-download-buttons {
  display: flex;
  flex-wrap: wrap;
}

.report-notes-area {
  white-space: pre-wrap;
  padding: 0 1rem;
}

.report-notes {
  width: 100%;
  min-height: 150px;
}

.rpt-date {
  flex-grow: 1;
}

.rpt-btn {
  flex-grow: 1;
  max-width: 500px;
}

.week-of {
  display: flex;
  align-items: center;
}

.week-of h3 {
  margin-right: auto;
}

.week-report-button {
  padding: 0.5rem !important;
  font-size: 0.9em;
}


.contained-printable-report {
  padding: 1rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}


.report-container {
  background-color: #fff;
  padding: 3rem 2rem;
  margin: 1rem auto;
  width: 900px;
}

.report-container h2 {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 1rem;
}

.report-doc-heading {
  display: flex;
  align-items: center;
  margin: 0 0 1rem 0;
}
.report-doc-heading h1 {
  margin: 0 0 1rem 0;
  padding : 0;
}

.report-logo {
  display: block;
  max-width: 100px;
  margin-right: 3rem;
}

.print-report-worker {
  display: flex;
}
.print-report-worker div {
  width: 200px;
}

.print-report-worker-name-title, .print-report-worker-title-title, .print-report-worker-hours-title {
  font-weight: bold;
}

.print-report-worker-title, .print-report-worker-hours,
.print-report-worker-title-title, .print-report-worker-hours-title {
  text-align: center;
}
.print-report-worker-title {
  font-style: italic;
}

.report-table {
  margin: 1rem 0;
  font-size: 0.9em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.report-table-item, .report-table-header {
  display: flex;
  align-items: center;
  transition: background-color 0.2s ease-in-out;
}
.rt-piles div {
  width: calc((100% - 50px) / 4);
  text-align: center;
  padding: 0.1rem;
  padding: 0.5rem 0.25rem;
}
.rt-caissons div {
  width: calc((100% - 50px) / 15 );
  text-align: center;
  padding: 0.1rem;
  padding: 0.5rem 0.25rem;
}
.report-table-header.rt-caissons {
  font-size: 0.8em;
}
.rt-id {
  width: 50px !important;
}
.rt-calc {
  font-style: italic;
  background-color: rgba(0, 0, 0, 0.025);
}

.report-table-header {
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
}

.report-table-item:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}

.report-table-item:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.report-print-button {
  cursor: pointer;
  text-decoration: none;

  background-color: var(--theme);
  border: 1px solid var(--themedarkhighlight);
  color: var(--light-font);
  
  padding: 0.75rem 1.5rem;
  margin: 0.25rem;
  border-radius: 0.25rem;
  text-align: center;
  
  overflow: hidden;
  transition: background-color 0.2s ease-in-out;
}
.report-print-button:hover {
  background-color: var(--themedarkhighlight);
}

.send-email {
  z-index: 30;
  background-color: var(--background);
}

.email-status {
  background-color: rgb(245, 157, 43);
  padding: 1rem;
  text-align: center;
  color: #fff;
}


.report-email-button {
  display: block;
  margin: 1rem 0;
  width: 100%;
}


.email-field {
  width: 100%;
  display: flex;
  align-items: center;
  
}
.email-field label {
  display: block;
  width: 80px;
}

.email-input {
  margin: 1rem;
  font-size: 1em;
  width: 100%;
}

.email-text {
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 0.75rem;
  margin: 1rem 0;
  width: 100%;
  min-height: 100px;
}

.report-capitalization {
  text-transform: capitalize;
}

.ts-week-worker-container {
  display: flex;
}

.ts-week-cell {
  width: calc(100% / 8);
  padding: 0.25rem 0.5rem;
}

.ts-week-hours, .ts-week-hour-header {
  text-align: center;
}

.ts-warning {
  text-align: center;
  background-color: var(--theme);
  color: #ffffff;
  padding: 1rem;
  margin: 1rem;
  border-radius: 0.25rem;
}

.ts-week-header, .ts-week-worker {
  display: flex;
}

.ts-total-hour-summary {
  display: flex;
}

.ts-total-hour-title {
  width: 150px;
  padding: 0 0.5rem;
}

.ts-week-worker-container:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}

.ts-week-header {
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  padding: 0.25rem 0;
}

.ts-total-hour-summary {
  padding: 0.25rem;
}
.ts-total-hour-summary:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}

.ts-total-header-summary {
  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  padding: 0.5rem 0.25rem;
  display: flex;
}

.print-report-worker {
  padding: 0.25rem;
}

.print-report-worker:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}






.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media (min-width: 970px) {
  .rpt-btn {
    max-width: 100%;
  }
}



.quill {
  background-color: #fff;
}

.ql-editor {
  min-height: 100px;
}



.print-page-break {
  page-break-after: always;
}