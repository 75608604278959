/* Form */

input {
  border: none;
  padding: 0.5rem;
  font-size: 1.2em;
  background-color: #fff;
  border-radius: 0.25rem;
  overflow: hidden;
}

input.login-field {
  width: 80%;
  max-width: 300px;
  margin: 0 0 0.5rem 0;
  border: 1px solid var(--theme);
}
input.login-field::placeholder {
  font-size: 0.8em;
  font-style: italic;
}
.login-button {
  width: 80%;
  max-width: 300px;
}


.button {
  cursor: pointer;

  background-color: var(--theme);
  border: 1px solid var(--themedarkhighlight);
  color: var(--light-font);
  
  padding: 0.75rem 1.5rem;
  margin: 0.25rem;
  border-radius: 0.25rem;
  text-align: center;
  
  overflow: hidden;
  transition: background-color 0.2s ease-in-out;
}

.button:hover {
  background-color: var(--themedarkhighlight);
}

.cancel {
  background-color: #eee !important;
  color: var(--theme);
  border: 1px solid var(--theme);
}