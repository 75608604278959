:root {
  --theme: #97131b;
  --themedarkhighlight: rgb(117, 18, 18);
  --font: #333;
  --light-font: #fff;
  --background: #eee;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;

  font-family: 'Montserrat', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--background);
  color: var(--font);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  margin: 1rem 0;
  padding: 0;
}

